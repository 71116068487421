<template>
	<div class="connectWallet">
		<div class="title">
			<div class="wallet">{{$t('连接钱包')}}</div>
			<div class="opt">{{$t('选择要连接的钱包')}}</div>
		</div>
		<img class="tupian" @click="goTo('/friend')" src="@/assets/bracelet_img/21.png" alt="">
		<div class="box">
			<div class="card">
				<div class="cardTitle">{{$t('钱包')}}</div>
				<div class="row row_mar_btm">
					<img class="img1" src="@/assets/bracelet_img/35.png" alt="">
					<span>TOKEN POCKET</span>
				</div>
				<div class="row row_mar_btm">
					<img class="img2" src="@/assets/bracelet_img/34.png" alt="">
					<span>METAMASK</span>
				</div>
				<div class="row row_mar_btm">
					<img class="img3" src="@/assets/bracelet_img/37.png" alt="">
					<span>WALLET CONNECT</span>
				</div>
				<div class="row">
					<img class="img4" src="@/assets/bracelet_img/36.png" alt="">
					<span>TRUST WALLET</span>
				</div>
			</div>
		</div>
		<div class="btnGroup"></div>
	</div>
</template>

<script>
	export default {
		
		data() {
			return {
				keyword: '',
				currentRate: 0,
			}
		},
		created(){},
		mounted(){
			
		},
		methods: {
			goTo(){
				this.$router.push({
					path: "/friendAdd"
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.connectWallet {
		width: 100%;
		min-height: 100vh;
		padding-top: 48px;
		background: url(~@/assets/bracelet_img/26.png) no-repeat;
		background-size: 100% 100%;
		color: #ffffff;
		.title{
			padding: 8px 0 11px 20px;
      		background: url(~@/assets/bracelet_img/20.png) no-repeat;
		  	background-size: 150% 100%;
      		background-position: center;
			.wallet{
				font-family: '071-SSRuiFengTi';
				font-size: 60px;
				margin-top: -8px;
			}
			.opt{
				font-family: 'TsangerYuYangT-W05';
				font-size: 12px;
				margin-top: -8px;
			}
		}
		.tupian{
			width: 204px;
			height: 15px;
    	}
		.box{
			padding: 26px 60px 40px;
			.card{
				width: 100%;
				height: 317px;
				background: rgba(0, 0, 0, 0.4);
				border-radius: 8px;
				padding: 19px 22px 38px;
				display: flex;
				flex-direction: column;
				align-items: center;
				.cardTitle{
					font-family: '071-SSRuiFengTi';
					font-size: 30px;
					margin-bottom: 28px;
				}
				.row{
					width: 100%;
					height: 43px;
					background-color: #6480f3;
					border-radius: 15px 3px 15px 3px;
					border: 2px solid rgba(0, 0, 0, 0.8);
					display: flex;
					justify-content: center;
					align-items: center;
					font-family: 'KenyanCoffeeRg-BoldItalic';
					font-size: 18px;
					.img1{
						width: 18px;
						height: 17px;
						margin-right: 8px;
					}
					.img2{
						width: 19px;
						height: 19px;
						margin-right: 8px;
					}
					.img3{
						width: 23px;
						height: 15px;
						margin-right: 9px;
					}
					.img4{
						width: 16px;
						height: 19px;
						margin-right: 10px;
					}
				}
				.row_mar_btm{
					margin-bottom: 13px;
				}
			}
		}
	}
</style>
